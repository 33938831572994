import * as React from 'react';

import Layout from '@components/layouts/layout';
import Navigation from '@components/navigation/navigation';
import Footer from '@components/footer/footer';
import {Image, Container, Row, Col} from 'react-bootstrap';
import {useIntl, FormattedMessage} from 'gatsby-plugin-intl';

import {TelephoneFill, EnvelopeFill, PersonFill} from 'react-bootstrap-icons';

import {Helmet} from 'react-helmet';

import proxy1Img from '@images/kontakt/proxy-1.jpg';
import proxy2Img from '@images/kontakt/proxy-2.jpg';
import proxy3Img from '@images/kontakt/proxy-3.jpg';
import serviceTeam1Img from '@images/kontakt/service-1.jpg';
import serviceTeam2Img from '@images/kontakt/service-2.jpg';
import serviceTeam3Img from '@images/kontakt/service-3.jpg';

const ContactPage = ({pageContext}) => {
  const intl = useIntl();

  return (
    <Layout>
      <Helmet>
        <title>{intl.locale === 'pl' ? 'Kontakt' : 'Contact'} – Studenckie Koła Naukowe AGH</title>
        <meta property="og:title" content={`Kontakt – Studenckie Koła Naukowe AGH`} />
      </Helmet>

      <Navigation customPagesNav={pageContext.customPagesNav} />

      <div className="kontakt">
        <Container className="pt-4 pb-4">
          <h1>
            <FormattedMessage id="contact.header" />
          </h1>
        </Container>
        <div className="kontakt__upper-section">
          <Container>
            <Row className="py-lg">
              <h3>
                <FormattedMessage id="contact.team" />
              </h3>
            </Row>
            <Row className="mb-5">
              <Col md={3} lg={3} className="d-flex justify-content-center mb-3">
                <Image src={serviceTeam1Img} fluid className="kontakt__image" />
              </Col>
              <Col md={4} lg={6}>
                <h5>mgr Karolina Sochacka</h5>
                <p className="kontakt__desc">
                  <FormattedMessage id="contact.position" />
                </p>
              </Col>
              <Col md={5} lg={3}>
                <div className="kontakt__bottom-section__contact-data">
                  <TelephoneFill className="kontakt__icon" />
                  <p>+48 12 617 32 66</p>
                </div>
                <div className="kontakt__bottom-section__contact-data">
                  <TelephoneFill className="kontakt__icon" />
                  <p>+48 885 970 725</p>
                </div>
                <div className="kontakt__bottom-section__contact-data">
                  <EnvelopeFill className="kontakt__icon" />
                  <p>sochacka@agh.edu.pl</p>
                </div>
                <div className="kontakt__bottom-section__contact-data">
                  <PersonFill className="kontakt__icon" />
                  <p>
                    <a
                      title="SkOs AGH"
                      target="_blank"
                      rel="noreferrer"
                      href="https://skos.agh.edu.pl/osoba/karolina-sochacka-8273.html"
                    >
                      SkOs AGH
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col md={3} lg={3} className="d-flex justify-content-center mb-3">
                <Image src={serviceTeam2Img} fluid className="kontakt__image" />
              </Col>
              <Col md={4} lg={6}>
                <h5>mgr inż. Małgorzata Kustra</h5>
                <p className="kontakt__desc">
                  <FormattedMessage id="contact.position1" />
                </p>
              </Col>
              <Col md={5} lg={3}>
                <div className="kontakt__bottom-section__contact-data">
                  <TelephoneFill className="kontakt__icon" />
                  <p>+48 12 617 32 66</p>
                </div>
                <div className="kontakt__bottom-section__contact-data">
                  <EnvelopeFill className="kontakt__icon" />
                  <p>mkustra@agh.edu.pl</p>
                </div>
                <div className="kontakt__bottom-section__contact-data">
                  <PersonFill className="kontakt__icon" />
                  <p>
                    <a
                      title="SkOs AGH"
                      target="_blank"
                      rel="noreferrer"
                      href="https://skos.agh.edu.pl/osoba/malgorzata-kustra-10284.html"
                    >
                      SkOs AGH
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col md={3} lg={3} className="d-flex justify-content-center mb-3">
                <Image src={serviceTeam3Img} fluid className="kontakt__image" />
              </Col>
              <Col md={4} lg={6}>
                <h5>mgr Ewelina Wywrot</h5>
                <p className="kontakt__desc">
                  <FormattedMessage id="contact.position2" />
                </p>
              </Col>
              <Col md={5} lg={3}>
                <div className="kontakt__bottom-section__contact-data">
                  <TelephoneFill className="kontakt__icon" />
                  <p>+48 12 617 32 66</p>
                </div>
                <div className="kontakt__bottom-section__contact-data">
                  <EnvelopeFill className="kontakt__icon" />
                  <p>ewywrot@agh.edu.pl</p>
                </div>
                <div className="kontakt__bottom-section__contact-data">
                  <PersonFill className="kontakt__icon" />
                  <p>
                    <a
                      title="SkOs AGH"
                      target="_blank"
                      rel="noreferrer"
                      href="https://skos.agh.edu.pl/osoba/ewelina-wywrot-10285.html"
                    >
                      SkOs AGH
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="py-lg kontakt__bottom-section">
          <Container className="mb-5">
            <Row>
              <h2>
                <FormattedMessage id="contact.reps" />
              </h2>
            </Row>
            <Row className="pt-5">
              <Col md={3} lg={3} className="d-flex justify-content-center mb-3">
                <Image src={proxy2Img} fluid className="kontakt__image" />
              </Col>
              <Col md={4} lg={6}>
                <h5>dr hab. inż. Paweł Bogacz, prof. AGH</h5>
                <p className="kontakt__desc">
                  <FormattedMessage id="contact.rep" />
                  <br />
                  <FormattedMessage id="contact.green" />
                </p>
              </Col>
              <Col md={5} lg={3}>
                <div className="kontakt__bottom-section__contact-data">
                  <TelephoneFill className="kontakt__icon" />
                  <p>+48 12 617 21 27</p>
                </div>
                <div className="kontakt__bottom-section__contact-data">
                  <EnvelopeFill className="kontakt__icon" />
                  <p>bogacz@agh.edu.pl</p>
                </div>
                <div className="kontakt__bottom-section__contact-data">
                  <PersonFill className="kontakt__icon" />
                  <p>
                    <a
                      title="SkOs AGH"
                      target="_blank"
                      rel="noreferrer"
                      href="https://skos.agh.edu.pl/osoba/pawel-bogacz-5379.html"
                    >
                      SkOs AGH
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="pt-5">
              <Col md={3} lg={3} className="d-flex justify-content-center mb-3">
                <Image src={proxy3Img} fluid className="kontakt__image" />
              </Col>
              <Col md={4} lg={6}>
                <h5>dr inż. Paweł Janowski</h5>
                <p className="kontakt__desc">
                  <FormattedMessage id="contact.rep" />
                  <br />
                  <FormattedMessage id="contact.black" />
                </p>
              </Col>
              <Col md={5} lg={3}>
                <div className="kontakt__bottom-section__contact-data">
                  <TelephoneFill className="kontakt__icon" />
                  <p>+48 12 617 57 15</p>
                </div>
                <div className="kontakt__bottom-section__contact-data">
                  <EnvelopeFill className="kontakt__icon" />
                  <p>pjanowsk@agh.edu.pl</p>
                </div>
                <div className="kontakt__bottom-section__contact-data">
                  <PersonFill className="kontakt__icon" />
                  <p>
                    <a
                      title="SkOs AGH"
                      target="_blank"
                      rel="noreferrer"
                      href="https://skos.agh.edu.pl/osoba/pawel-janowski-8364.html"
                    >
                      SkOs AGH
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="pt-5">
              <Col md={3} lg={3} className="d-flex justify-content-center mb-3">
                <Image src={proxy1Img} fluid className="kontakt__image" />
              </Col>
              <Col md={4} lg={6}>
                <h5>dr inż. Joanna Augustyn-Nadzieja</h5>
                <p className="kontakt__desc">
                  <FormattedMessage id="contact.rep" />
                  <br />
                  <FormattedMessage id="contact.red" />
                </p>
              </Col>
              <Col md={5} lg={3}>
                <div className="kontakt__bottom-section__contact-data">
                  <TelephoneFill className="kontakt__icon" />
                  <p>+48 12 617 25 67</p>
                </div>
                <div className="kontakt__bottom-section__contact-data">
                  <EnvelopeFill className="kontakt__icon" />
                  <p>
                    janadzieja@agh.edu.pl
                    <br />
                    joanna.augustyn-nadzieja@agh.edu.pl
                  </p>
                </div>
                <div className="kontakt__bottom-section__contact-data">
                  <PersonFill className="kontakt__icon" />
                  <p>
                    <a
                      title="SkOs AGH"
                      target="_blank"
                      rel="noreferrer"
                      href="https://skos.agh.edu.pl/osoba/joanna-augustyn-nadzieja-6073.html"
                    >
                      SkOs AGH
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>

          <div className="py-lg kontakt__upper-section">
            <Container>
              <Row className="mb-5">
                <Col md={12} lg={12}>
                  <h5>
                    <FormattedMessage id="contact.agh" />
                  </h5>
                  <h5>
                    <FormattedMessage id="contact.dss" />
                  </h5>
                  <p>al. Mickiewicza 30</p>
                  <p>30-059 Kraków</p>
                  <p>
                    <FormattedMessage id="contact.location" />
                  </p>
                  <p>
                    <FormattedMessage id="contact.room" />
                  </p>
                  <div className="mb-3 kontakt__upper-section__contact-data">
                    <EnvelopeFill className="kontakt__icon" />
                    <p>dss@agh.edu.pl</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  );
};

export default ContactPage;
